<template>
  <div class="container content">
    <div class="border border-white bg-white mb-2">
      <div v-html="contents.section1.txt"></div>
    </div>
    <iframe src="https://players.brightcove.net/644391012001/5xC7AvkxM_default/index.html?videoId=6044563577001"></iframe>
  </div>
</template>

<script>
export default {
  name: 'Company',
  props:['globalData','pageId'],
  data(){
    return {

    }
  },
  computed:{
    userLanguage(){
      return this.globalData.init.language
    },
    contents(){
      let language = this.userLanguage
      let contents = this.globalData.contents[language][this.pageId]
      console.log(contents)

      this.globalData.init.head.pageId = this.pageId
      this.globalData.init.head.title = contents.head.title
      this.globalData.init.head.keywords = contents.head.keywords
      this.globalData.init.head.description = contents.head.description

      return contents
    }
  }
}
</script>
<style scoped>
iframe{
  width: 100%;
  height: 566px;
  margin-bottom: 2rem;
}
</style>
